import React from "react";

const AiContactCenter = () => {
    return (
        <svg
            style={{ width: "1.063rem", height: "1.125rem" }}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.56591 8.50088L3.50391 8.50088C2.97347 8.50088 2.46476 8.71159 2.08969 9.08666C1.71462 9.46173 1.50391 9.97044 1.50391 10.5009L1.50391 14.5009C1.50391 15.0313 1.71462 15.54 2.08969 15.9151C2.46476 16.2902 2.97347 16.5009 3.5039 16.5009L4.56591 16.5009C4.80949 18.4343 5.75037 20.2123 7.21194 21.5011C8.6735 22.79 10.5552 23.5011 12.5039 23.5009L12.5039 21.5009C10.9126 21.5009 9.38648 20.8687 8.26126 19.7435C7.13605 18.6183 6.5039 17.0922 6.50391 15.5009L6.50391 9.50088C6.50391 7.90958 7.13605 6.38345 8.26126 5.25824C9.38648 4.13302 10.9126 3.50088 12.5039 3.50088C14.0952 3.50088 15.6213 4.13302 16.7465 5.25824C17.8718 6.38345 18.5039 7.90958 18.5039 9.50088L18.5039 16.5009L21.5039 16.5009C22.0343 16.5009 22.543 16.2902 22.9181 15.9151C23.2932 15.54 23.5039 15.0313 23.5039 14.5009L23.5039 10.5009C23.5039 9.97044 23.2932 9.46174 22.9181 9.08666C22.543 8.71159 22.0343 8.50088 21.5039 8.50088L20.4419 8.50088C20.1979 6.56777 19.2568 4.79015 17.7953 3.50155C16.3338 2.21296 14.4524 1.50195 12.5039 1.50195C10.5555 1.50195 8.67397 2.21295 7.21248 3.50155C5.75099 4.79015 4.80994 6.56777 4.56591 8.50088ZM21.5039 10.5009L21.5039 14.5009L20.5039 14.5009L20.5039 10.5009L21.5039 10.5009ZM4.50391 10.5009L4.50391 14.5009L3.50391 14.5009L3.50391 10.5009L4.50391 10.5009ZM16.7439 16.2859L15.6839 14.5899C14.7309 15.187 13.6286 15.5028 12.5039 15.5009C11.3793 15.5028 10.2769 15.187 9.3239 14.5899L8.26391 16.2859C9.53458 17.0822 11.0043 17.5033 12.5039 17.5009C14.0035 17.5033 15.4732 17.0822 16.7439 16.2859Z"
                fill="#007BFF"
            />
        </svg>
    );
};

export default AiContactCenter;
