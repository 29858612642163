import React from "react";

const Reminder = () => {
    return (
        <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 9.24788L11.375 8.43538V6.5C11.373 5.34901 10.9642 4.23577 10.2208 3.35706C9.47738 2.47835 8.44726 1.89075 7.3125 1.69813V0H5.6875V1.69813C4.55274 1.89075 3.52262 2.47835 2.77921 3.35706C2.0358 4.23577 1.62698 5.34901 1.625 6.5V8.43538L0 9.24788V13H4.875V14.625H8.125V13H13V9.24788ZM11.375 11.375H1.625V10.2521L3.25 9.43962V6.5C3.25 5.63805 3.59241 4.8114 4.2019 4.2019C4.8114 3.59241 5.63805 3.25 6.5 3.25C7.36195 3.25 8.1886 3.59241 8.7981 4.2019C9.40759 4.8114 9.75 5.63805 9.75 6.5V9.43962L11.375 10.2521V11.375Z"
                fill="#007BFF"
            />
        </svg>
    );
};

export default Reminder;
