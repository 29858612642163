import React, { useEffect, useReducer, useRef, useState } from "react";
import { io } from "socket.io-client";
import { Api } from "../../../apis";

const SocketIO = () => {
    const socket = io(Api?.contactCenterSocketUrl, { path: "/unstructured_data/kyc_data", autoConnect: false });
    const [callRef, setCallRef] = useState<any>({
        connect: false,
        disconnect: false,
        error: false,
        close: false,
        callData: {},
    });

    useEffect(() => {
        socket.connect();
        socket.on("connect", () => {
            console.log("connected");
            setCallRef((prev: any) => ({ ...prev, connect: true }));
        });
        socket.on("call_intiated", (data) => {
            setCallRef((prev: any) => {
                if (data[Object?.keys(data)[0]]?.is_processed) {
                    const { [Object?.keys(data)[0]]: filter, ...remain } = prev?.callData;
                    return {
                        ...prev,
                        callData: remain,
                    };
                } else {
                    return { ...prev, callData: { ...prev?.callData, ...data } };
                }
            });
        });
        socket.on("disconnect", (reason) => {
            console.log("Disconnected:", reason);
            setCallRef((prev: any) => ({ ...prev, disconnect: true }));
        });
        socket.on("close", (reason) => {
            console.log("close:", reason);
            setCallRef((prev: any) => ({ ...prev, close: true }));
        });
        socket.on("connect_error", (error) => {
            console.error("Connection Error:", error);
            setCallRef((prev: any) => ({ ...prev, error: true }));
        });

        return () => {
            socket?.off();
        };
    }, []);

    return { callRef };
};

export default SocketIO;
