import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../../context/setting";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import {
    Box,
    Button,
    OutlinedInput,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { KeyboardArrowLeftSharp } from "@mui/icons-material";
import Reminder from "../../../components/Common/svgLogs/Reminder";
import { Api } from "../../../apis";
import moment from "moment";
import Typing from "../../AI_Images/Typing";

const Details = ({ setType }: { setType: any }) => {
    const scrollRef = useRef<any>();
    const { contextStore, setContext } = useContext<any>(Context);
    const [data, setData] = useState<any>({});
    const [loader, setLoader] = useState(false);
    const [selected, setSelected] = useState<any>({});

    useEffect(() => {
        setLoader(true);
        if (contextStore?.ai_contact_center?.selectedId?.is_processed) {
            Api?.fetchCallDetails(contextStore?.ai_contact_center?.selectedId?.call_id)
                ?.then((res: any) => {
                    if (res?.status === 200) {
                        setData(res?.data?.data);
                        setTimeout(() => {
                            scrollRef?.current?.lastElementChild?.scrollIntoView({
                                behavior: "smooth",
                                block: "end",
                                inline: "end",
                            });
                        }, 10);
                    } else {
                        setData({});
                    }
                    setLoader(false);
                })
                ?.catch((err) => {
                    console.log(err);
                    setLoader(false);
                });
        } else {
            const findId: any = contextStore?.ai_contact_center?.ongoing?.find(
                (item: any) => item?.call_id === selected?.call_id
            );
            if (findId) {
                setData(findId);
            } else if (selected?.call_id) {
                setType("log");
            }
            setTimeout(() => {
                scrollRef?.current?.lastElementChild?.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "end",
                });
            }, 10);
            setLoader(false);
        }
    }, [selected?.call_id, contextStore?.ai_contact_center?.ongoing]);

    useEffect(() => {
        if (contextStore?.ai_contact_center?.selectedId) {
            setSelected(contextStore?.ai_contact_center?.selectedId);
        }
    }, [contextStore?.ai_contact_center?.selectedId]);

    const handleSave = () => {
        Api?.updateCallDetails({
            call_id: data?.call_id,
            kyc_details: data?.kyc_details,
            notes: data?.note || "",
        })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    let temp = contextStore?.ai_contact_center?.ongoing?.filter(
                        (item: any) => item?.call_id !== data?.call_id
                    );
                    setContext({
                        ...contextStore,
                        ai_contact_center: {
                            ...contextStore?.ai_contact_center,
                            ongoing: [...temp],
                        },
                    });
                    setType("log");
                } else {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Something Went Wrong! Please try after some time",
                        },
                    });
                }
            })
            ?.catch((err) => {
                console.log(err);
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Something Went Wrong! Please try after some time",
                    },
                });
            });
    };

    return (
        <ReflexContainer orientation="vertical">
            <ReflexElement flex={1} minSize={600}>
                <Stack p={2} sx={{ height: "inherit" }}>
                    <Stack direction={"row"} gap={2} alignItems={"center"}>
                        <Button
                            startIcon={
                                <KeyboardArrowLeftSharp
                                    sx={{ position: "absolute", left: "4px", bottom: "9px", opacity: 0 }}
                                />
                            }
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                                pl: 2.4,
                                "&:hover svg": { left: "0px", opacity: 1 },
                                "& *": {
                                    transition: "ease-in-out 200ms 50ms",
                                },
                            }}
                            onClick={() => setType("log")}
                        >
                            Back
                        </Button>
                        <Typography>{selected?.call_id}</Typography>
                    </Stack>
                    <Stack sx={{ border: "1px solid #D9D9D9", flex: "1 0 10vh", overflowY: "auto" }}>
                        <Table stickyHeader>
                            <TableHead>
                                {["Speaker", "Customer"]?.map((head) => (
                                    <TableCell
                                        size="small"
                                        sx={{ textAlign: "center", background: "#EFF8FE", border: "1px solid #E3F4FF" }}
                                    >
                                        <Typography>{head}</Typography>
                                    </TableCell>
                                ))}
                            </TableHead>
                            <TableBody ref={scrollRef}>
                                {loader ? (
                                    <Skeleton sx={{ minWidth: "50%", height: "2rem" }} />
                                ) : data?.transcript ? (
                                    <>
                                        {data?.transcript?.map((body: any, index: number) => (
                                            <TableRow
                                                sx={{
                                                    "& pre": {
                                                        whiteSpace: "pre-wrap",
                                                        fontFamily: "Gilroy-Regular",
                                                        fontSize: "14px",
                                                    },
                                                    "& td": {
                                                        border: "1px solid #D9D9D9",
                                                        width: "50%",
                                                    },
                                                }}
                                            >
                                                <TableCell>
                                                    {index % 2 === 0 && (
                                                        <Typography>
                                                            <pre>
                                                                {`${moment(body?.timestamp)?.format("hh:mm:ss a")}\n`}
                                                            </pre>
                                                            <pre style={{ fontFamily: "Gilroy-Medium" }}>
                                                                {"\t"}
                                                                {body?.text}
                                                            </pre>
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {index % 2 === 1 && (
                                                        <Typography>
                                                            <pre>
                                                                {`${moment(body?.timestamp)?.format("hh:mm:ss a")}\n`}
                                                            </pre>
                                                            <pre style={{ fontFamily: "Gilroy-Medium" }}>
                                                                {"\t"}
                                                                {body?.text}
                                                            </pre>
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {!selected?.is_proccessed && (
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell
                                                    sx={{ borderLeft: "1px solid #D9D9D9", textAlign: "center" }}
                                                >
                                                    <Typing />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </TableBody>
                        </Table>
                        {data?.transcript && data?.transcript?.length === 0 && (
                            <Stack justifyContent={"center"} alignItems={"center"} p={2}>
                                <Typography>No Transcript Recieved</Typography>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </ReflexElement>
            <ReflexSplitter style={{ width: "auto" }}>
                <Stack
                    direction={"row"}
                    sx={{
                        height: "100%",
                        width: "16px",
                        background: "#E3F4FF",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                    }}
                >
                    <Box
                        sx={{
                            width: "3px",
                            height: "15px",
                            background: "#0000001C",
                            borderRadius: "10px",
                        }}
                    ></Box>
                    <Box
                        sx={{
                            width: "3px",
                            height: "15px",
                            background: "#0000001C",
                            borderRadius: "10px",
                        }}
                    ></Box>
                </Stack>
            </ReflexSplitter>
            <ReflexElement flex={1} minSize={400} style={{ display: "flex", flexDirection: "column" }}>
                <Stack p={2} gap={2} sx={{ flex: "1 0 10vh", overflowY: "auto" }}>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography>Customer Details</Typography>
                        <Stack direction={"row"} alignItems={"center"} gap={2}>
                            {/* <Button variant="text">Cancel</Button> */}
                            <Button variant="contained" sx={{ background: "#007BFFF" }} onClick={handleSave}>
                                Save
                            </Button>
                        </Stack>
                    </Stack>
                    <Stack gap={1}>
                        {[
                            "name",
                            "Spouse/Father_Name",
                            "address",
                            "phone_number",
                            "pan_card",
                            "aadhaar",
                            "Marital_Status",
                            "Other_details,_if_any",
                        ]?.map((field) => (
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <Typography color={"#484848"} fontSize={"small"}>
                                    {field
                                        ?.split("_")
                                        ?.map((ini) => ini[0]?.toUpperCase() + ini?.slice(1))
                                        ?.join(" ")}
                                </Typography>
                                {field === "address" ? (
                                    <textarea
                                        value={data?.kyc_details ? data?.kyc_details[field] : ""}
                                        onChange={(e: { target: { value: string } }) =>
                                            setData((prev: any) => {
                                                let temp = { ...prev };
                                                if (prev?.call_id) {
                                                    temp.kyc_details[field] = e?.target?.value;
                                                }
                                                return temp;
                                            })
                                        }
                                        style={{
                                            minWidth: "50%",
                                            height: "2rem",
                                            background: "#fff",
                                            borderRadius: "4px",
                                            borderColor: "rgb(0 0 0 / 23%)",
                                        }}
                                    />
                                ) : loader ? (
                                    <Skeleton sx={{ minWidth: "50%", height: "2rem" }} />
                                ) : (
                                    <OutlinedInput
                                        value={data?.kyc_details ? data?.kyc_details[field] : ""}
                                        onChange={(e: { target: { value: string } }) =>
                                            setData((prev: any) => {
                                                let temp = { ...prev };
                                                if (prev?.call_id) {
                                                    temp.kyc_details[field] = e?.target?.value;
                                                }
                                                return temp;
                                            })
                                        }
                                        sx={{ minWidth: "50%", height: "2rem", fontSize: "0.9rem", background: "#fff" }}
                                    />
                                )}
                            </Stack>
                        ))}
                    </Stack>
                    <Stack>
                        <Typography
                            color={"#007BFF"}
                            fontSize={"small"}
                            fontWeight={"bold"}
                            pb={1}
                            sx={{ fontSize: "0.9rem" }}
                        >
                            Notes
                        </Typography>
                        <Typography variant="caption" color={"#484848"} fontSize={"small"}>
                            Write your points to remember for future conversations.
                        </Typography>
                        {loader ? (
                            <Skeleton sx={{ minWidth: "50%", height: "4rem" }} />
                        ) : (
                            <OutlinedInput
                                value={data?.kyc_details ? data?.kyc_details["note"] : ""}
                                onChange={(e: { target: { value: string } }) =>
                                    setData((prev: any) => {
                                        let temp = { ...prev };
                                        if (prev?.call_id) {
                                            temp.kyc_details["note"] = e?.target?.value;
                                        }
                                        return temp;
                                    })
                                }
                                maxRows={3}
                                multiline
                                sx={{ fontSize: "0.9rem", color: "#484848" }}
                                spellCheck={true}
                            />
                        )}
                    </Stack>
                    <Stack>
                        <Typography
                            color={"#007BFF"}
                            fontSize={"small"}
                            fontWeight={"bold"}
                            pb={1}
                            sx={{ fontSize: "0.9rem" }}
                        >
                            Reminder
                        </Typography>
                        <Stack gap={0.6}>
                            {loader ? (
                                <Skeleton sx={{ minWidth: "50%", height: "1.5rem" }} />
                            ) : Array?.isArray(data?.reminders) ? (
                                data?.reminders?.map((remind: any) => (
                                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                                        <Reminder />
                                        <Typography color={"#484848"} fontSize={"small"}>
                                            {remind?.task} Scheduled For {remind?.time} {remind?.date}
                                        </Typography>
                                    </Stack>
                                ))
                            ) : (
                                <></>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </ReflexElement>
        </ReflexContainer>
    );
};

export default Details;
