import { Box, Button, CircularProgress, IconButton, OutlinedInput, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../context/setting";
import SendWhiteIcon from "../../components/Common/svgLogs/SendWhiteIcon";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import axios from "axios";
import { ArrowRight, ChevronLeftSharp, ConstructionOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ReactPlayer from "react-player";
import "../../index.css";

const TafTable = () => {
    const videoRef = useRef<HTMLVideoElement | any>(null);
    const navigate = useNavigate();
    const [query, setQuery] = useState<any>(null);
    const { contextStore, setContext } = useContext<any>(Context);
    const [togglePanel, setTogglePanel] = useState<boolean>(false);
    const [currentFile, setCurrentFile] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchDetails = async () => {
        setLoading(true);
        try {
            const resp: any = await axios
                .post(`https://unstruct-api-ssx.softsensor.ai/unstructured_data/taf_discussion?request=${query}`)
                .then((res) => {
                    setData(res?.data);
                });
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setContext({
                ...contextStore,
                snackState: {
                    open: true,
                    errorType: "error",
                    message: "Server issue! Please try after some time",
                },
            });
        }
    };

    const handlePlay = (timeStamp: number) => {
        // Seek to the start time before playing
        if (videoRef.current) {
            videoRef.current.currentTime = timeStamp; // Set the video start time
            videoRef.current.play();
        }
    };

    function timeToSeconds(time: any) {
        let seconds: any = time.split(":").map(Number);
        if (seconds.length === 1) {
            seconds = seconds;
        } else if (seconds.length === 2) {
            seconds = seconds[1] + seconds[0] * 60;
        } else {
            seconds = seconds[2] + seconds[1] * 60 + seconds[0] * 3600;
        }
        return seconds;
    }

    const highlightTimestamp = (text: any) => {
        const timestampRegex = /\b(\d{2}:\d{2}:\d{2}|\d{2}:\d{2})\b/g;

        return text.split(timestampRegex).map((part: any, index: any) => {
            if (timestampRegex.test(part)) {
                return (
                    <span
                        key={index}
                        style={{ color: "#007BFF", fontWeight: "600", cursor: "pointer" }}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            if (videoRef.current) videoRef.current.seekTo(timeToSeconds(part), "seconds");
                        }}
                    >
                        {" "}
                        {part}
                    </span>
                );
            } else {
                return (
                    <span key={index} style={{ color: "#000", fontWeight: "600" }}>
                        {" "}
                        {/* Change color to blue */}
                        {part}
                    </span>
                );
            }
            return part;
        });
    };

    const renderers = {
        // Customize how strong text is rendered
        strong: ({ children }: any) => {
            const childText = React.Children.map(children, (child) =>
                typeof child === "string" ? child : child.props.children
            ).join("");
            return <>{highlightTimestamp(childText) || children}</>; // Highlight timestamps only
        },
    };

    return (
        <Box sx={{ width: "100%", p: 4 }}>
            {loading ? (
                <Box
                    sx={{
                        background: "rgba(255, 255, 255, 0.2)",
                        width: "100vw",
                        height: "100vh",
                        position: "absolute",
                        left: "0",
                        top: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : null}

            <ReflexContainer orientation="vertical">
                <ReflexElement className="left-pane">
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", mb: "5px" }}>
                        <Button sx={{ color: "#007BFF" }} onClick={() => navigate(-1)}>
                            <Box sx={{ display: "flex" }}>
                                <ChevronLeftSharp /> Back
                            </Box>
                        </Button>
                    </Box>

                    {data !== null && (
                        <Box
                            sx={{
                                width: "100%",
                                height: "77vh",
                                mb: "1vh",
                                overflowY: "scroll",
                                border: "1px dotted #D0D0D0",
                                borderRadius: "10px",
                            }}
                        >
                            <Box
                                sx={{
                                    borderRadius: "10px",
                                    display: "flex",
                                    width: "100%",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "20%",
                                        borderRight: "1px dotted #D0D0D0",
                                        display: "flex",
                                        py: 1.4,
                                        justifyContent: "center",
                                        color: "#007BFF",
                                        borderBottom: "1px solid #D0D0D0",
                                    }}
                                >
                                    File Title
                                </Box>
                                <Box
                                    sx={{
                                        width: "80%",
                                        py: 1.4,
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "#007BFF",
                                        borderBottom: "1px solid #D0D0D0",
                                    }}
                                >
                                    Results
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    borderRadius: "10px",
                                }}
                            >
                                {data?.map((item: any) => {
                                    return (
                                        <Box
                                            sx={{ width: "100%", display: "flex" }}
                                            onClick={() => setCurrentFile(item)}
                                        >
                                            <Box
                                                sx={{
                                                    width: "20%",
                                                    borderRight: "1px dotted #D0D0D0",
                                                    display: "flex",
                                                    p: 1,
                                                    justifyContent: "center",
                                                    borderBottom: "1px dotted #D0D0D0",
                                                    textOverflow: "clip",
                                                }}
                                            >
                                                {item?.filename}
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: "80%",
                                                    p: 1,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    borderBottom: "1px dotted #D0D0D0",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Markdown
                                                    className="markdown"
                                                    components={renderers}
                                                    remarkPlugins={[remarkGfm]}
                                                >
                                                    {item?.response}
                                                </Markdown>
                                                {item?.timestamp?.length > 0 &&
                                                    item?.timestamp?.map((time: any) => {
                                                        return (
                                                            <Box sx={{ display: "flex", gap: 1 }}>
                                                                <ArrowRight />
                                                                <Typography
                                                                    sx={{
                                                                        color: "#007BFF",
                                                                        fontWeight: "600",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={(e: any) => {
                                                                        e.stopPropagation();
                                                                        if (videoRef.current)
                                                                            videoRef.current.seekTo(
                                                                                timeToSeconds(time),
                                                                                "seconds"
                                                                            );
                                                                    }}
                                                                >
                                                                    {time}
                                                                </Typography>
                                                            </Box>
                                                        );
                                                    })}
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                    )}
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: data === null ? "absolute" : null,
                            bottom: data === null ? "1vh" : "0vh",
                        }}
                    >
                        <OutlinedInput
                            value={query}
                            onChange={(e: { target: { value: string }; preventDefault: any }) => {
                                setQuery(e.target.value);
                            }}
                            onKeyDown={(e: any) => {
                                if (e?.key === "Enter") {
                                    if (e?.target?.value?.length === 0) {
                                        setContext({
                                            ...contextStore,
                                            snackState: {
                                                open: true,
                                                errorType: "warning",
                                                message: "Please Ask A Question",
                                            },
                                        });
                                    } else {
                                        setQuery(e?.target?.value);
                                        fetchDetails();
                                    }
                                }
                                return;
                            }}
                            fullWidth
                            endAdornment={
                                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                    <IconButton
                                        sx={{ background: "#007BFF", "&:hover": { background: "#007BFFaa" } }}
                                        onClick={(e: any) => {
                                            if (query?.length === 0) {
                                                setContext({
                                                    ...contextStore,
                                                    snackState: {
                                                        open: true,
                                                        errorType: "warning",
                                                        message: "Please Ask A Question",
                                                    },
                                                });
                                            } else {
                                                fetchDetails();
                                            }
                                        }}
                                    >
                                        <SendWhiteIcon />
                                    </IconButton>
                                </Box>
                            }
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "80px",
                                    border: "1px solid #E0E0E0",
                                },
                                "& .MuiOutlinedInput-input": {
                                    padding: "10px 20px",
                                },
                                width: "80%",
                            }}
                            placeholder="Please ask your query"
                        />
                    </Box>
                </ReflexElement>

                {togglePanel ||
                    (currentFile !== null && (
                        <ReflexSplitter>
                            <Box
                                sx={{
                                    width: "2px",
                                    height: "100%",
                                    background: "#E3F4FF",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-evenly",
                                    borderWidth: "0px 2px 0px 2px",
                                    borderStyle: "solid",
                                    borderColor: "#E3F4FF",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "3px",
                                        height: "15px",
                                        background: "#0000001C",
                                        borderRadius: "10px",
                                    }}
                                ></Box>
                                <Box
                                    sx={{
                                        width: "3px",
                                        height: "15px",
                                        background: "#0000001C",
                                        borderRadius: "10px",
                                    }}
                                ></Box>
                            </Box>
                        </ReflexSplitter>
                    ))}

                {togglePanel ||
                    (currentFile !== null && (
                        <ReflexElement className="right-pane" minSize={400} size={650}>
                            {currentFile?.filetype === "excel" ? (
                                <DocViewer
                                    style={{ width: "100%", height: "100%" }}
                                    documents={[
                                        {
                                            uri: currentFile?.filepath,
                                            fileType: "xls",
                                        },
                                    ]}
                                    pluginRenderers={DocViewerRenderers}
                                />
                            ) : currentFile?.filetype === "pptx" ? (
                                <DocViewer
                                    style={{ width: "100%", height: "100%", marginLeft: "40px" }}
                                    documents={[
                                        {
                                            uri: currentFile?.filepath,
                                            fileType: "ppt",
                                        },
                                    ]}
                                    pluginRenderers={DocViewerRenderers}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        mt: 5,
                                    }}
                                >
                                    <ReactPlayer
                                        ref={videoRef}
                                        url="https://youtu.be/H-Dqket4cRI" // Replace with your video URL
                                        className="react-player"
                                        width="100%"
                                        height="35vh"
                                        controls={true}
                                    />
                                </Box>
                            )}
                        </ReflexElement>
                    ))}
            </ReflexContainer>
        </Box>
    );
};

export default TafTable;
