import React, { useEffect, useRef, useState } from "react";
import InputField from "./InputField";
import { Box, Button, IconButton, Modal, Stack, Tooltip, Typography } from "@mui/material";
import Typing from "../../Chat/Effects/Typing";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ShowPPTIcon from "../../../components/Common/svgLogs/Finance_CFO/ShowPPtIcon";
import ShowPPT from "../../Finance/ShowPPT";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MessageBox = ({
    varianceSocketHooks,
    varianceMsgRef,
    fileName,
}: {
    varianceSocketHooks: any;
    varianceMsgRef: any;
    fileName: any;
}) => {
    const scrollRef = useRef<any>();
    const navigate = useNavigate();
    const [msgList, setMsgList] = useState<any[]>([{ out: true, msg: "How can I help you?", latest: false }]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showPPT, setShowPPT] = useState<boolean>(false);

    const handelSend = (value: string) => {
        varianceMsgRef.load = true;
        setMsgList((prev) => [...prev, { out: true, msg: value, latest: false }, { in: true, msg: "", latest: true }]);
        varianceSocketHooks?.sendJsonMessage({ query: value });
    };

    useEffect(() => {
        setLoading(() => (varianceMsgRef?.load ? true : false));
        if (varianceMsgRef?.load === false) {
            setMsgList((prev: any[]) => {
                return prev?.map((message) => {
                    if (message?.latest) {
                        message.latest = false;
                        message.msg = varianceMsgRef?.msg;
                        varianceMsgRef.msg = "";
                    }
                    return message;
                });
            });
            scrollRef?.current?.lastElementChild?.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "end",
            });
        }
    }, [varianceMsgRef?.load]);

    return (
        <Box
            sx={{
                p: 1.5,
                display: "flex",
                flexDirection: "column",
                transition: "250ms ease-in",
                justifyContent: "space-between",
                gap: 1,
                height: "92vh",
            }}
        >
            <Box sx={{ width: "95%", alignSelf: "center" }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        width: "100%",
                        justifyContent: "space-between",
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                            onClick={() => navigate("/variance_analysis")}
                            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        >
                            <ArrowBackIos sx={{ fontSize: "1.2rem", pl: 0.7 }} />
                        </IconButton>
                        <Typography variant="h6">{fileName}</Typography>
                    </Box>
                    <Tooltip title="Show PPT">
                        <Button
                            sx={{
                                textTransform: "none",
                                background: "#fff",
                                color: "#007BFF",
                                height: "50px",
                                borderRadius: "7.31px",
                                border: "1px solid #007BFF",
                                "&:hover": { background: "#fff" },
                            }}
                            onClick={() => setShowPPT(true)}
                        >
                            <ShowPPTIcon />
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
            <Stack ref={scrollRef} sx={{ flex: "1 0 10vh", overflowY: "auto", p: 1.5 }}>
                {msgList?.map((item: any, index: number) => (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.8,
                            background: item?.in ? "#fff" : "",
                            py: 2,
                            px: item?.in ? 2 : 1,
                            border: item?.in ? "1px solid #F2F2F2" : "",
                            borderRadius: item?.in ? "10px" : "",
                            ml: item?.in ? 0 : 1,
                        }}
                    >
                        {index === msgList?.length - 1 && loading && (
                            <Typing
                                onClick={() =>
                                    setMsgList((prev: any[]) => {
                                        return prev?.map((message) => {
                                            if (message?.latest) {
                                                message.latest = false;
                                                message.msg = (
                                                    <Typography color={"error"}>Response Terminated</Typography>
                                                );
                                            }
                                            return message;
                                        });
                                    })
                                }
                            />
                        )}
                        <Typography
                            sx={{
                                flex: "1 0 auto",
                                overflowX: "auto",
                                "& td": {
                                    border: "1px solid gray",
                                    textAlign: "center !important",
                                    p: 0.4,
                                },
                                "& th": {
                                    border: "1px solid gray",
                                    textAlign: "center",
                                    p: 0.7,
                                    background: "#e3f4ff",
                                    minWidth: "7rem",
                                },
                                "& table": { borderCollapse: "collapse" },
                            }}
                        >
                            <Markdown remarkPlugins={[remarkGfm]}>{item?.msg}</Markdown>
                        </Typography>
                    </Box>
                ))}
            </Stack>
            <Box sx={{ width: "100%", p: 1.5, alignSelf: "center" }}>
                <InputField handleSend={handelSend} />
            </Box>
            <Modal open={showPPT} onClose={() => setShowPPT(false)}>
                <ShowPPT onClose={setShowPPT} report="variance" />
            </Modal>
        </Box>
    );
};

export default MessageBox;
