import React, { useContext, useEffect, useState } from "react";
import Logs from "./logs/Logs";
import Details from "./details/Details";
import SocketIO from "./utils/SocketIO";
import { Context } from "../../context/setting";

const ContactCenter = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [type, setType] = useState<"log" | "detail">("log");
    const { callRef } = SocketIO();
    useEffect(() => {
        const filtered = Object?.keys(callRef?.callData)?.map((item) => ({
            timestamp: new Date(),
            is_reminder: false,
            is_processed: false,
            call_id: item,
            ...callRef?.callData[item],
        }));
        setContext({
            ...contextStore,
            ai_contact_center: {
                ...contextStore?.ai_contact_center,
                ongoing: [...filtered],
            },
        });
    }, [callRef?.callData]);

    switch (type) {
        case "log":
            return <Logs setType={setType} />;
        case "detail":
            return <Details setType={setType} />;

        default:
            return <Logs setType={setType} />;
    }
};

export default ContactCenter;
