import {
    Box,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LogoXIcon from "../../../components/Common/svgLogs/LogoXIcon";
import LoadingDots from "../../Chat/Effects/LoadingDots";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MessageBox = ({ msg, loading }: any) => {
    const [open, setOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
    const [load, setLoad] = useState<boolean>(false);
    useEffect(() => {
        if (typeof loading === "boolean") {
            setLoad(loading);
        }
    }, [loading]);

    const handleOpen = (payload: any) => {
        setOpen(true);
        setModalImage(payload);
    };
    const handleClose = () => {
        setOpen(false);
        setModalImage("");
    };

    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "720px",
        height: "fit-content",
        bgcolor: "background.paper",
        // border: "2px solid #000",
        borderRadius: "12px",
        boxShadow: 24,
        p: 4,
    };
    return (
        <Box sx={{ my: 1 }}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img
                        src={modalImage}
                        alt="graph"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            cursor: "pointer",
                            // border:"2px solid red"
                        }}
                    />
                </Box>
            </Modal>
            {msg?.out && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <LogoXIcon />
                    <Typography>{msg?.msg}</Typography>
                </Box>
            )}
            {msg?.in && (
                <Box sx={{ pl: 4, py: 0.5 }}>
                    {msg?.latest && load ? (
                        <LoadingDots />
                    ) : (
                        <>
                            {msg.msg?.visual ? (
                                <Box
                                    sx={{
                                        maxWidth: "60%",
                                        maxHeight: "50%",
                                        p: 1,
                                        boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.3)",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <img
                                        src={`data:image/jpeg;base64,${msg.msg?.visual}`}
                                        alt="graph"
                                        style={{
                                            width: "90%",
                                            height: "90%",
                                            objectFit: "contain",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => handleOpen(`data:image/jpeg;base64,${msg.msg?.visual}`)}
                                    />
                                </Box>
                            ) : msg.msg.table ? (
                                <Table sx={{ border: "1px solid #DCDCDC" }}>
                                    <TableHead>
                                        <TableRow>
                                            {Object.keys(JSON?.parse(msg?.msg?.table)[0]).map((header, index) => (
                                                <TableCell
                                                    key={index}
                                                    sx={{
                                                        fontWeight: "bold",
                                                        whiteSpace: "nowrap",
                                                        border: "1px solid #DCDCDC",
                                                    }}
                                                >
                                                    {header
                                                        .split("_")
                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                        .join(" ")}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.isArray(JSON?.parse(msg?.msg?.table)) &&
                                            JSON?.parse(msg?.msg?.table).map((entry: any, entryIndex: any) => (
                                                <TableRow
                                                    key={entryIndex}
                                                    // sx={{ whiteSpace: "nowrap" }}
                                                >
                                                    {Object?.keys(entry)?.map((subEntry) => (
                                                        <TableCell>{entry[subEntry]}</TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            ) : (
                                (msg.msg?.text || typeof msg?.msg === "string") && (
                                    <Typography
                                        sx={{
                                            p: 0.5,
                                            fontSize: "0.92rem",
                                            flex: "1 0 10vh",
                                            overflowX: "auto",
                                            "& td": {
                                                border: "1px solid gray",
                                                textAlign: "center !important",
                                                p: 0.4,
                                            },
                                            "& th": {
                                                border: "1px solid gray",
                                                textAlign: "center",
                                                p: 0.7,
                                                background: "#e3f4ff",
                                                minWidth: "7rem",
                                            },
                                            "& table": { borderCollapse: "collapse" },
                                        }}
                                    >
                                        <Markdown remarkPlugins={[remarkGfm]}>{msg.msg?.text || msg?.msg}</Markdown>
                                    </Typography>
                                )
                            )}
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default MessageBox;
